import React from "react";
import {
    Edit,
    PasswordInput,
    required,
    SaveButton,
    SimpleForm,
    TextInput,
    Toolbar,
    useNotify,
    useRefresh
} from "react-admin";
import withStyles from "@material-ui/core/styles/withStyles";

const EditToolbar = props => (
    <Toolbar {...props}>
        <SaveButton label="Speichern" />
    </Toolbar>
);

const styles = {
    password: {
        width: 500
    }
};

const validatePassword = (value, allValues) => {
    let { newPassword, newPasswordConfirm } = allValues;
    return newPassword === newPasswordConfirm ? "" : "Passwörter stimmen nicht überein";
};

export const ProfileEdit = withStyles(styles)(({ classes, staticContext, ...props }) => {
    const notify = useNotify();
    const refresh = useRefresh();

    const refreshOnSuccess = () => {
        notify("ra.notification.updated", "info", { smart_count: 1 });
        refresh();
    };

    return (
        <React.Fragment>
            <Edit
                {...props}
                redirect={false} // needs no redirect, there's no list page
                undoable={false}
                id="me" // id needs to be set for GET_ONE/UPDATE call, but isn't actually used since the user only has one profile and the jwt is used for identifying the user
                resource="profile"
                basePath="my-profile"
                title="Mein Profil" // titles of the two edits are concatenated
                onSuccess={refreshOnSuccess}
            >
                <SimpleForm toolbar={<EditToolbar />}>
                    <TextInput source="username" label="E-mailadresse" disabled />
                    <TextInput source="mobile" label="Mobiltelefonnummer" validate={required()} />
                </SimpleForm>
            </Edit>
            <Edit
                {...props}
                redirect={false} // needs no redirect, there's no list page
                undoable={false}
                id="password" // id needs to be set for GET_ONE/UPDATE call, but isn't actually used since the user only has one profile and the jwt is used for identifying the user. use for password update endpoint, get returns empty data
                resource="profile"
                basePath="my-profile"
                title={" "} // titles of the two edits are concatenated. empty string however triggers default
                onSuccess={refreshOnSuccess}
            >
                <SimpleForm toolbar={<EditToolbar />}>
                    <PasswordInput
                        source="newPassword"
                        label="Neues Passwort"
                        validate={required()}
                        className={classes.password}
                    />
                    <PasswordInput
                        source="newPasswordConfirm"
                        label="Neues Passwort wiederholen *"
                        validate={validatePassword}
                        className={classes.password}
                    />
                </SimpleForm>
            </Edit>
        </React.Fragment>
    );
});
