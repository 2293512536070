import React, { Children, cloneElement } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import classNames from "classnames";
import MuiAppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { createStyles, withStyles } from "@material-ui/core/styles";
import MenuIcon from "@material-ui/icons/Menu";
import withWidth from "@material-ui/core/withWidth";
import compose from "recompose/compose";
import { toggleSidebar as toggleSidebarAction } from "ra-core";
import { LoadingIndicator, Responsive, useLocale } from "react-admin";
import UZHAdminUserMenu from "./UZHAdminUserMenu.js";

const styles = theme =>
    createStyles({
        menuButton: {
            marginLeft: "0.5em",
            marginRight: "0.5em"
        },
        menuButtonIconClosed: {
            transition: theme.transitions.create(["transform"], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen
            }),
            transform: "rotate(0deg)"
        },
        menuButtonIconOpen: {
            transition: theme.transitions.create(["transform"], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen
            }),
            transform: "rotate(180deg)"
        },
        title: {
            flex: 1,
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
            marginLeft: 16
        },
        applicationName: {
            flex: 1,
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textAlign: "center",
            marginLeft: 8
        },
        buttons: {
            flex: 1,
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            overflow: "hidden",
            marginRight: 24
        },
        git: {
            flex: 1,
            fontSize: 12,
            textAlign: "right",
            marginRight: 24,
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden"
        }
    });

const isDev = process.env.REACT_APP_DEV === "true";

const AppBar = ({
                    children,
                    classes,
                    className,
                    logo,
                    logout,
                    open,
                    title,
                    toggleSidebar,
                    userMenu,
                    width,
                    ...rest
                }) => {
    useLocale(); // to trigger redraw on locale change
    return (
        <MuiAppBar className={className} color="secondary" {...rest}>
            <Toolbar disableGutters variant={width === "xs" ? "regular" : "dense"}>
                <Responsive
                    small={
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={toggleSidebar}
                            className={classNames(classes.menuButton)}
                        >
                            <MenuIcon
                                className={classNames(open ? classes.menuButtonIconOpen : classes.menuButtonIconClosed)}
                                widht="40px"
                                height="40px"
                            />
                        </IconButton>
                    }
                    medium={null}
                />
                {Children.count(children) === 0 ? (
                    <Typography variant="h6" color="inherit" className={classes.title} id="react-admin-title" />
                ) : (
                    children
                )}
                <Typography variant="h6" color="inherit" className={classes.applicationName}>
                    {isDev ? "UZH SOS 24/7 Cockpit - DEV" : "UZH SOS 24/7 Cockpit - Produktion"}
                </Typography>
                <span className={classes.buttons}>
                    {isDev && <span className={classes.git}>Commit {process.env.REACT_APP_GIT_INFO}</span>}
                    <LoadingIndicator />
                    {cloneElement(userMenu, { logout })}
                </span>
            </Toolbar>
        </MuiAppBar>
    );
};

AppBar.propTypes = {
    children: PropTypes.node,
    classes: PropTypes.object,
    className: PropTypes.string,
    logout: PropTypes.element,
    open: PropTypes.bool,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
    toggleSidebar: PropTypes.func.isRequired,
    userMenu: PropTypes.node,
    width: PropTypes.string
};

AppBar.defaultProps = {
    userMenu: <UZHAdminUserMenu />
};

const enhance = compose(
    connect(() => ({}), {
        toggleSidebar: toggleSidebarAction
    }),
    withStyles(styles),
    withWidth()
);

export default enhance(AppBar);
