import "../App.css";
import { createMuiTheme } from "@material-ui/core/styles";
import { red } from "@material-ui/core/colors";

const isDev = process.env.REACT_APP_DEV === "true";

export const COLORS = {
    blue: isDev ? "#dc6027" : "#0028a5",
    midnightBlue: "#14233c",
    midnightLight: "#d0d3d8"
};

const UZHAdminTheme = createMuiTheme({
    palette: {
        primary: { main: COLORS.blue },
        secondary: { main: COLORS.blue },
        error: { main: red[900] },
        contrastThreshold: 3,
        tonalOffset: 0.2
    },
    typography: {
        fontFamily: "Open Sans"
    },
    overrides: {
        MuiTableRow: {
            head: {
                backgroundColor: COLORS.midnightLight,
                "& > th ": {
                    color: COLORS.midnightBlue,
                    fontWeight: "bold",
                    fontSize: 14
                }
            }
        },
        MuiTypography: {
            body1: {
                color: COLORS.midnightBlue
            }
        },
        MuiTableSortLabel: {
            active: {
                color: COLORS.blue
            },
            root: {
                "&:focus": {
                    color: COLORS.blue
                }
            }
        }
    }
});

export default UZHAdminTheme;
