import React from "react";
import { Layout } from "react-admin";
import UZHAdminAppBar from "./UZHAdminAppBar.js";
import UZHAdminSidebar from "./UZHAdminSidebar.js";
import UZHAdminMenu from "./UZHAdminMenu.js";

const UZHAdminLayout = props => (
    <Layout {...props} appBar={UZHAdminAppBar} sidebar={UZHAdminSidebar} menu={UZHAdminMenu} />
);

export default UZHAdminLayout;
