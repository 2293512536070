import React from "react";
import { Admin, Resource } from "react-admin";
import dataProvider from "./dataProvider/dataprovider.js";
import addBase64ImageUploadFeature from "./dataProvider/addBase64ImageUpload.js";
import UZHAdminLayout from "./layout/UZHAdminLayout.js";
import UZHAdminTheme from "./layout/UZHAdminTheme.js";
import StatusIcon from "@material-ui/icons/List";
import { AlertList, AlertEdit, AlertCreate } from "./resources/Alert";
import authProvider from "./authProvider/authProvider";
import englishMessages from "ra-language-english";
import germanMessages from "./translations/german";
import polyglotI18nProvider from "ra-i18n-polyglot";
import { messagesDe } from "./translations/domainMessages";
import LoginPage from "./customComponents/LoginForm";
import { ProfileEdit } from "./resources/Profile";
import { Route } from "react-router";

const base64CapableUploadProvider = addBase64ImageUploadFeature(dataProvider);

const messages = {
    en: englishMessages,
    de: { ...germanMessages, ...messagesDe }
};

const i18nProvider = polyglotI18nProvider(locale => messages[locale], "de", { allowMissing: true });

const App = () => (
    <Admin
        layout={UZHAdminLayout}
        theme={UZHAdminTheme}
        dataProvider={base64CapableUploadProvider}
        authProvider={authProvider}
        loginPage={LoginPage}
        i18nProvider={i18nProvider}
        customRoutes={[<Route key="my-profile" path="/my-profile" component={ProfileEdit} />]}
    >
        {/* checking if access_token (local storage) is set prevents firing a request when user is not logged in and directly goes to login page */}
        {access_token => {
            return [
                <Resource name="alert" list={AlertList} edit={AlertEdit} create={AlertCreate} icon={StatusIcon} />,
                <Resource name="profile" />];
        }}
    </Admin>
);
export default App;
