import React from "react";
import { MenuItemLink, UserMenu } from "react-admin";
import SettingsIcon from "@material-ui/icons/Settings";

const UZHAdminUserMenu = props => {
    return (
        <UserMenu {...props}>
            <MenuItemLink to="/my-profile" primaryText="Mein Profil" leftIcon={<SettingsIcon />} />
        </UserMenu>
    );
};
export default UZHAdminUserMenu;
