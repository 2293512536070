import { AUTH_CHECK, AUTH_ERROR, AUTH_GET_PERMISSIONS, AUTH_LOGIN, AUTH_LOGOUT } from "react-admin";
import { UserManager } from "oidc-client";

import jwt_decode from "jwt-decode";

const issuer = process.env.REACT_APP_IDP_ISSUER;
const clientId = process.env.REACT_APP_IDP_CLIENT_ID;
const redirectUri = process.env.REACT_APP_LOGIN_URL;

const urlEncode = body =>
    Object.entries(body)
        .map(([key, value]) => `${key}=${value}`)
        .join("&");

const userManager = new UserManager({
    authority: issuer,
    client_id: clientId,
    redirect_uri: redirectUri,
    response_type: "code",
    scope: "openid profile",
    post_logout_redirect_uri: redirectUri
});

const cleanup = () => {
    // Remove the ?code&state from the URL
    window.history.replaceState({}, window.document.title, window.location.origin);
    // remove oidc local storage entries
    Object.entries(localStorage)
        .map(x => x[0])
        .filter(x => x.substring(0, 5) === "oidc.")
        .map(x => localStorage.removeItem(x));
};

const getTokenEndpoint = async () => {
    const configResponse = await fetch(`${issuer}/.well-known/openid-configuration`);
    const openidConfiguration = await configResponse.json();

    const { token_endpoint } = openidConfiguration;
    return token_endpoint;
};

const tradeForToken = async (token_endpoint, code, code_verifier) => {
    const tokenResponse = await fetch(token_endpoint, {
        method: "POST",
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        },
        body: urlEncode({
            client_id: clientId,
            redirect_uri: redirectUri,
            code,
            code_verifier,
            grant_type: "authorization_code"
        })
    });
    if (!tokenResponse.ok) {
        throw new Error("Could not trade code for token");
    }
    const tokenResponsePayload = await tokenResponse.json();
    return {
        token_string: tokenResponsePayload["access_token"],
        id_token: tokenResponsePayload["id_token"],
        token: jwt_decode(tokenResponsePayload["access_token"])
    };
};

const authProvider = async (type, params = {}) => {
    if (type === AUTH_LOGIN) {
        if (!params.code || !params.state) {
            // we don't have any state so just redirect to the idp
            userManager.signinRedirect();
            return;
        }
        try {
            // fetch local code_verifier for PKCE
            const stateKey = `oidc.${params.state}`;
            const { code_verifier } = JSON.parse(localStorage.getItem(stateKey) || "{}");

            const token_endpoint = await getTokenEndpoint();
            const tokenResponse = await tradeForToken(token_endpoint, params.code, code_verifier);
            localStorage.setItem("access_token", tokenResponse.token_string);
            localStorage.setItem("id_token", tokenResponse.id_token);
            cleanup();
            userManager.clearStaleState();
            return Promise.resolve(); // always resolve. reject redirects to login page, which is where we already are (so no reload). resolve triggers a reload of the login page, which solves the login circle of death
        } catch (error) {
            cleanup();
            console.error(error);
            userManager.clearStaleState();
            throw error;
        }
    }

    if (type === AUTH_ERROR) {
        const status = params.status;
        if (status === 401 || status === 403) {
            localStorage.removeItem("access_token");
            return Promise.reject();
        }
        return Promise.resolve();
    }

    if (type === AUTH_LOGOUT) {
        const loggedIn = localStorage.getItem("access_token") ? true : false;
        if (loggedIn) {
            userManager.signoutRedirect({ id_token_hint: localStorage.getItem("id_token") });
            userManager.clearStaleState();
        }
        localStorage.removeItem("access_token");
        localStorage.removeItem("id_token");
        return Promise.resolve();
    }

    if (type === AUTH_CHECK) {
        return localStorage.getItem("access_token") ? Promise.resolve() : Promise.reject();
    }

    if (type === AUTH_GET_PERMISSIONS) {
        // no roles, just check if user is logged in
        return localStorage.getItem("access_token") ? Promise.resolve() : Promise.reject();
    }

    console.log("Unknown method:", type);
    return Promise.reject("Unknown method");
};

export default authProvider;
