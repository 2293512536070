/**
 * Convert a `File` object returned by the upload input into a base 64 string.
 * That's not the most optimized way to store images in production, but it's
 * enough to illustrate the idea of data provider decoration.
 */
const convertFileToBase64 = file =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.rawFile);

        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
    });

/**
 * For companies and custom numbers update/create only, convert uploaded image
 * in base 64 and attach it to the `imagebase64` sent property.
 */
const addBase64ImageUploadFeature = requestHandler => (type, resource, params) => {
    if ((type === "UPDATE" || type === "CREATE") && (resource === "companies" || resource === "customNumbers")) {
        if (params.data.image) {
            console.log(params.data.image);
            const picture = params.data.image;
            return convertFileToBase64(picture).then(function(base64Picture) {
                return requestHandler(type, resource, {
                    ...params,
                    data: {
                        ...params.data,
                        imageBase64: base64Picture
                    }
                });
            });
        }
    } else if (type === "GET_LIST" && (resource === "companies" || resource === "customNumbers")) {
        console.log(params);
    }

    // for other request types and resources, fall back to the default request handler
    return requestHandler(type, resource, params);
};

export default addBase64ImageUploadFeature;
