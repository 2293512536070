import React from "react";
import { connect } from "react-redux";
import { getResources, MenuItemLink, Responsive, useTranslate } from "react-admin";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";

const useMenuStyles = makeStyles(theme => ({
    active: {
        color: theme.palette.primary.main,
        "& svg": {
            color: theme.palette.primary.main
        }
    }
}));

const useContainerStyles = makeStyles({
    linkContainer: {
        marginTop: 16
    }
});

const UZHAdminMenu = ({ resources, onMenuClick, logout }) => {
    const translate = useTranslate();
    const menuClasses = useMenuStyles();
    const containerClasses = useContainerStyles();
    return (
        <div className={containerClasses.linkContainer}>
            {resources.map(
                resource =>
                    resource.hasList && (
                        <MenuItemLink
                            key={resource.name}
                            to={`/${resource.name}`}
                            primaryText={
                                (resource.options && resource.options.label) ||
                                translate(`resources.${resource.name}.name`, { smart_count: 2 })
                            }
                            leftIcon={React.createElement(resource.icon)}
                            onClick={onMenuClick}
                            sidebarIsOpen
                            classes={menuClasses}
                        />
                    )
            )}
            <Responsive
                small={logout}
                medium={null} // Pass null to render nothing on larger devices
            />
        </div>
    );
};

const mapStateToProps = state => ({
    resources: getResources(state)
});

export default withRouter(connect(mapStateToProps)(UZHAdminMenu));
