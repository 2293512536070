import React, { useState } from "react";
import {
    List,
    Datagrid,
    TextField,
    DateField,
    BooleanField,
    ChipField,
    Filter,
    TextInput,
    BooleanInput,
    Edit,
    Create,
    SimpleForm,
    required,
    regex,
    Button,
    Confirm,
    Toolbar,
    DeleteButton
} from "react-admin";
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid";
import Pagination from "../customComponents/Pagination";
import { useForm } from "react-final-form";

const AlertFilter = ({ classes, ...props }) => (
    <Filter {...props}>
        <TextInput label="Suche" source="q" alwaysOn />
    </Filter>
);

export const AlertList = props => (
    <List
        {...props}
        title="Alarm"
        filters={<AlertFilter />}
        exporter={false}
        bulkActionButtons={false}
        sort={{ field: "createdAt", order: "DESC" }}
        perPage={25}
        pagination={<Pagination />}
    >
        <Datagrid rowClick="edit">
            <TextField source="titleDe" label="Titel" />
            <TextField source="subtitleDe" label="Text" />
            <BooleanField source="push" label="Push" />
            <ChipField source="pushState" label="Push Status" />
            <DateField source="createdAt" label="Erstellt am" showTime locales="de-DE" />
            <DateField source="lastModified" label="Letzte Änderung" showTime locales="de-DE" />
        </Datagrid>
    </List>
);

const InputWrapper = ({ children, wrapperType = "div", wrapperOptions = {}, addDiv = false, ...props }) => {
    const childrenWithProps = React.Children.map(children, child => {
        let new_child = React.cloneElement(child, { ...props });
        if (addDiv) new_child = React.createElement("div", {}, new_child);
        return new_child;
    });

    return React.createElement(wrapperType, wrapperOptions, childrenWithProps);
};

const formStyles = {
    wrappedInput: {
        display: "inline-block"
    }
};

const urlValidation = regex(
    /(https?:\/\/)(www\.)?[a-zA-Z0-9@:%._+~#=-]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?/&=]*)/,
    "Keine korrekte Url."
);

const AlertSaveButton = () => {
    const [open, setOpen] = useState(false);
    const form = useForm();

    const handleClick = () => setOpen(true);
    const handleDialogClose = () => setOpen(false);
    const handleConfirm = () => {
        if (open) {
            setOpen(false);
            form.submit();
        }
    };

    return (
        <>
            <Button variant="contained" label="Alarm senden" onClick={handleClick} />
            <Confirm
                isOpen={open}
                title="Alarm senden"
                content="Bist du sicher, dass du diesen Alarm senden möchtest?"
                onConfirm={handleConfirm}
                onClose={handleDialogClose}
            />
        </>
    );
};

const EditToolbar = ({ isEdit = false, ...props }) => (
    <Toolbar {...props} style={{ justifyContent: "space-between" }}>
        <AlertSaveButton />
        {isEdit && <DeleteButton />}
    </Toolbar>
);

const AlertForm = withStyles(formStyles)(({ classes, isEdit = false, ...props }) => (
    <SimpleForm {...props} submitOnEnter={false} redirect="list" toolbar={<EditToolbar isEdit={isEdit} />}>
        <BooleanInput source="push" initialValue={false} />
        <InputWrapper wrapperType={Grid} wrapperOptions={{ container: true }}>
            <InputWrapper wrapperType={Grid} wrapperOptions={{ item: true, xs: 12, md: 6 }} addDiv>
                <TextInput source="titleDe" validate={required()} label="Titel De" />
                <TextInput source="subtitleDe" validate={required()} label="Text De" />
                <TextInput source="linkDe" validate={urlValidation} type="url" label="Url De" />
                <TextInput source="linkTitleDe" label="Urltext De" />
            </InputWrapper>
            <InputWrapper wrapperType={Grid} wrapperOptions={{ item: true, xs: 12, md: 6 }} addDiv>
                <TextInput source="titleEn" validate={required()} label="Titel En" />
                <TextInput source="subtitleEn" validate={required()} label="Text En" />
                <TextInput source="linkEn" validate={urlValidation} type="url" label="Url En" />
                <TextInput source="linkTitleEn" label="Urltext En" />
            </InputWrapper>
        </InputWrapper>
    </SimpleForm>
));

export const AlertEdit = props => (
    <Edit {...props} title="Alarm Bearbeiten">
        <AlertForm isEdit />
    </Edit>
);

export const AlertCreate = props => (
    <Create {...props} title="Alarm Erstellen">
        <AlertForm />
    </Create>
);
