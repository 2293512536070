import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Notification, useLogin, useNotify, userLogin as userLoginAction } from "react-admin";

import { createStyles, makeStyles } from "@material-ui/core/styles";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import { Card, Paper } from "@material-ui/core";
import Uzh_Background from "../images/uzh4.jpg";
import CircularProgress from "@material-ui/core/CircularProgress";
import { ReactComponent as UzhSvgIcon } from "../icons/uzh-logo.svg";

const useStyles = makeStyles(theme =>
    createStyles({
        main: {
            display: "flex",
            flexDirection: "column",
            minHeight: "100vh",
            alignItems: "center",
            justifyContent: "flex-start",
            background: `url(${Uzh_Background})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center"
        },
        card: {
            minWidth: 300,
            marginTop: "6em"
        },
        icon: {
            color: theme.palette.primary.main,
            marginRight: "1em"
        },
        avatar: {
            // maxHeight: "10em",
            maxWidth: "20em",
            margin: "1em",
            display: "flex",
            justifyContent: "center"
        },
        button: {
            width: "100%"
        }
    })
);

const LoginForm = ({ userLogin }) => {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const login = useLogin();
    const notify = useNotify();

    useEffect(() => {
        const { searchParams } = new URL(window.location.href);
        const code = searchParams.get("code");
        const state = searchParams.get("state");

        // If code is present, we came back from the provider
        if (code && state) {
            setLoading(true);
            login({ code, state })
                .catch(() => notify("ra.auth.sign_in_error", "warning"))
                .finally(setLoading(false));
        } else {
            setLoading(false);
        }
    }, [userLogin, login]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleLogin = () => {
        setLoading(true);
        login();
    };

    return (
        <Paper>
            <form onSubmit={handleLogin}>
                <div className={classes.main}>
                    <Card className={classes.card}>
                        <div className={classes.avatar}>
                            <UzhSvgIcon height="10em" />
                        </div>
                        <CardActions className={classes.actions}>
                            <Button
                                className={classes.button}
                                variant="contained"
                                type="submit"
                                color="primary"
                                // onClick={handleLogin}
                                disabled={loading}
                            >
                                {loading && <CircularProgress className={classes.icon} size={18} thickness={2} />}
                                Login
                            </Button>
                        </CardActions>
                    </Card>
                    <Notification />
                </div>
            </form>
        </Paper>
    );
};

const mapDispatchToProps = {
    userLogin: userLoginAction
};

export default connect(undefined, mapDispatchToProps)(LoginForm);
