export const messagesDe = {
    resources: {
        contents: {
            name: "Inhalt |||| Inhalte",
            forcedCaseName: "Inhalt |||| Inhalte",
            fields: {}
        },
        alert: {
            name: "Alarm |||| Alarme",
            forcedCaseName: "Alarm |||| Alarme",
            fields: {}
        },
        users: {
            name: "Benutzer",
            forcedCaseName: "Benutzer",
            fields: {}
        },
        usergroups: {
            name: "Gruppe |||| Gruppen",
            forcedCaseName: "Gruppe |||| Gruppen",
            fields: {}
        },
        pushregions: {
            name: "Region |||| Regionen",
            forcedCaseName: "Region |||| Regionen",
            fields: {}
        },
        textblocks: {
            name: "Textbaustein |||| Textbausteine",
            forcedCaseName: "Textbaustein |||| Textbausteine",
            fields: {}
        },
        urls: {
            name: "URL |||| URLs",
            forcedCaseName: "URL |||| URLs",
            fields: {}
        }
    }
};
